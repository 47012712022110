import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ModalOverlay from '../ModalOverlay'
import Button from '../Button/Loadable'
import './style.scss'

export default class ModalNotification extends PureComponent {
  getTitle() {
    let output = null
    if (this.props.title) {
      output = <div className="title-container">{this.props.title}</div>
    }
    return output
  }

  getModalMessage() {
    let output = null
    if (this.props.message) {
      output = <div className="message-container">{this.props.message}</div>
    }
    return output
  }

  getBtnContainer() {
    let output = null
    const isThereBtnProperty = this.props.onPrimaryButtonClick || this.props.onSecondaryButtonClick
    if (isThereBtnProperty) {
      const primaryBtn = <Button className="modal-btn" onClick={this.props.onPrimaryButtonClick} text={this.props.primaryButtonText} />
      const secondaryBtn = <Button className="modal-btn" onClick={this.props.onSecondaryButtonClick} text={this.props.secondaryButtonText} invert />
      output = (
        <div className="modal-btn-wrapper">
          {this.props.onSecondaryButtonClick ? secondaryBtn : null}
          {this.props.onPrimaryButtonClick ? primaryBtn : null}
        </div>
      )
    }
    return output
  }

  render() {
    return (
      <ModalOverlay onClose={this.props.onOverLayClose}>
        <div className={`modal-content-wrapper ${this.props.className}`}>
          {this.getTitle()}
          {this.getModalMessage()}
          {this.getBtnContainer()}
        </div>
      </ModalOverlay>
    )
  }
}

ModalNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onSecondaryButtonClick: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  onOverLayClose: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  primaryButtonText: PropTypes.string
}
