import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import './styled.scss'

import CloseIcon from '../../asset/images/modal-overlay/close.svg'

class ModalOverlay extends React.PureComponent {
  handleOverlayClicked() {
    this.props.onOverlayClicked && this.props.onOverlayClicked()
  }

  handleCloseBtnClicked = (e) => {
    e.stopPropagation()
    this.props.onClose()
  }

  getCloseBtn() {
    return (
      <div className="modal-close-btn" onClick={(e) => this.handleCloseBtnClicked(e)}>
        <img src={CloseIcon} alt="" />
      </div>
    )
  }

  render() {
    return (
      <ReactModal isOpen={true} className="application-modal-overlay" overlayClassName="application-modal-container">
        <div className="modal-container" onClick={() => this.handleOverlayClicked()}>
          <div className="content">
            {this.props.onClose ? this.getCloseBtn() : null}
            {this.props.children}
          </div>
        </div>
      </ReactModal>
    )
  }
}

ModalOverlay.propTypes = {
  onOverlayClicked: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.element
}

export default ModalOverlay
